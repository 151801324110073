import { useRouter } from 'next/router';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { createSession } from '@api/auth';
import { OTPForm } from '@components/auth';
import { PhoneNumber, defaultCountryCode } from '@components/common/PhoneInputField';
import routes from '@constants/routes';
import { useSetupSession } from '@hooks/sessions';
import { useGuestStore } from '@hooks/storage';

interface Props {
  contactNumber?: string;
  countryCode?: string;
}
const OTPContainer: React.FC<Props> = (props) => {
  const setupSession = useSetupSession();
  const { t } = useTranslation();

  const { clearGuestData } = useGuestStore();

  const { query, back, replace, push } = useRouter();

  const { contactNumber = props?.contactNumber || '', countryCode = props?.countryCode || defaultCountryCode } = query;

  const snackbarContext = useContext(SnackbarContext);

  const phoneNumber = useMemo(
    () =>
      ({
        contactNumber,
        countryCode,
      } as PhoneNumber),
    [contactNumber, countryCode]
  );

  const { mutate, isLoading } = useMutation(createSession, {
    onSuccess: (res) => {
      setupSession(res);
      clearGuestData();
      if (query?.guestMode) {
        push({
          pathname: routes.ROOT,
          query,
        });
        return;
      }
      back();
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const onSubmit = useCallback(
    (otpAttempt: string) => {
      mutate({
        ...phoneNumber,
        otpAttempt,
      });
    },
    [mutate, phoneNumber]
  );

  const resetPhoneNumber = () => {
    replace(routes.PROFILE_LOGIN);
  };

  return (
    <OTPForm
      onSubmit={onSubmit}
      isLoading={isLoading}
      phoneNumber={phoneNumber}
      resetPhoneNumber={resetPhoneNumber}
      showGuestNameInput
    />
  );
};

export default OTPContainer;
