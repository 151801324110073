import { addSeconds } from 'date-fns';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { getOTP } from '@api/auth';
import LoginForm from '@components/auth/LoginForm';
import { RESEND_OTP_TIME, USER_OTP_REQUEST_TIME_KEY } from '@constants/auth';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { setInternalCookie } from '@utils/auth';

const LoginContainer: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { query: routerQuery } = router;
  const snackbarContext = useContext(SnackbarContext);
  const { query } = usePersistentQueryParams();

  const { mutate, isLoading } = useMutation(getOTP, {
    onSuccess: async (_, request) => {
      const time = new Date().getTime();
      const reqCompletePhoneNumber = `${request?.phoneNumber.countryCode}${request?.phoneNumber.contactNumber}`;
      const cookieKey = `${USER_OTP_REQUEST_TIME_KEY}/${reqCompletePhoneNumber}`;
      const exp = addSeconds(new Date(), RESEND_OTP_TIME);
      setInternalCookie(null, cookieKey, String(time), {
        expires: new Date(exp),
        secure: true,
        sameSite: true,
        path: '/',
      });

      router.replace({
        pathname: routes.PROFILE_OTP,
        query: {
          ...query,
          ...(routerQuery?.guestMode && {
            guestMode: routerQuery.guestMode,
          }),
          contactNumber: request?.phoneNumber.contactNumber,
          countryCode: request?.phoneNumber.countryCode,
        },
      });
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  return <LoginForm onSubmit={mutate} isLoading={isLoading} isModalMode={false} />;
};

export default LoginContainer;
