import { PublicLayout } from '@layouts';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { AuthNavbar } from '@components/auth';
import { LoginContainer } from '@containers/auth';
import { useMetaTags } from '@hooks/common';
import { NextPageWithLayout } from '@interfaces/common';
import { buildTagHTML } from '@utils/seo';

const LoginPage: NextPageWithLayout = ({ brandUrl }) => {
  const { t } = useTranslation();
  const seoTags = useMetaTags({ pageTitle: t('sessions.login_page_title'), brandUrl });

  return (
    <>
      <Head>{buildTagHTML(seoTags)}</Head>
      <LoginContainer />
    </>
  );
};

LoginPage.getLayout = function getLayout(page) {
  return <PublicLayout NavbarComponent={AuthNavbar}>{page}</PublicLayout>;
};

export { getServerSideProps } from '@utils/seo';
export default LoginPage;
