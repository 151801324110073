import { useRouter } from 'next/router';
import { GuestNameForm } from '@components/auth';
import { defaultCountryCode } from '@components/common/PhoneInputField';
import { useCommonStore } from '@hooks/storage';

interface Props {
  contactNumber?: string;
  countryCode?: string;
}
const GuestContainer: React.FC<Props> = (props) => {
  const { query } = useRouter();

  const { contactNumber = props?.contactNumber || '', countryCode = props?.countryCode || defaultCountryCode } = query;

  const { storageState } = useCommonStore();

  return (
    <GuestNameForm
      contactNumber={contactNumber as string}
      countryCode={countryCode as string}
      isModalMode={false}
      skipPhoneNumber={!storageState.activeLocation?.requireGuestPhoneNumber}
    />
  );
};

export default GuestContainer;
